<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Content-->

      <div
        class="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0"
      >
        <a href="#" class="mb-15 text-center">
          <img src="/media/logos/agil logo.png" class="max-h-75px" alt="" />
        </a>

        <router-view></router-view>
      </div>

      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
export default {
  name: "autenticacao",
  methods: {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-4.jpg";
    },
  },
};
</script>
